import React from "react";
import { Helmet } from "react-helmet";
function SecondArticle() {
  return (
    <>
      <Helmet>
        <title>Как сделать заказ - Поле Чудес</title>
        <meta
          name="description"
          content="Руководство по заказу товаров в интернет-магазине 'Поле Чудес'."
        />
        <meta name="keywords" content="заказ, интернет-магазин, Поле Чудес" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <h1>Как сделать заказ в магазине "ПОЛЕ ЧУДЕС"</h1>

      <img
        className="large"
        src={process.env.PUBLIC_URL + "/img/articles/1/main.jpg"}
        alt=""
      />

      <h3>Краткое руководство по приобретению оружия в нашем магазине:</h3>

      <ul>
        <li>
          Отправьте оператору(
          <a href="https://t.me/YAKUB0VlCH_OPERAT0RS">@YAKUB0VlCH_OPERATORS</a>)
          в чат название вашего населенного пункта. Если доставка возможна в ваш
          регион, оператор предоставит вам всю необходимую информацию о нашем
          магазине.
        </li>

        <li>
          Перейдите в раздел актуального прайс-листа(
          <a href="/catalaog">Каталог</a> ), где вы найдете шесть категорий:
          "Травматические пистолеты", "Боевые пистолеты", "Травматические
          автоматы", "Боевые автоматы" и "Гранаты и патроны". Выберите нужную
          позицию и отправьте название модели оператору в чат(
          <a href="https://t.me/YAKUB0VlCH_OPERAT0RS">@YAKUB0VlCH_OPERATORS</a>
          ).
          <br />
          <br />
          <span className="bold">
            Пример: Я хотел бы приобрести травматический пистолет МР79-9тм.
          </span>
        </li>

        <li>
          Обсудите с оператором(
          <a href="https://t.me/YAKUB0VlCH_OPERAT0RS">@YAKUB0VlCH_OPERATORS</a>)
          наличие выбранного товара, уточните комплектацию и подтвердите ваш
          заказ.
        </li>

        <li>
          Оплатите заказ удобным для вас способом из предложенных: Биткоин либо
          Перевод на карту
          <br />
          <br />
          <span className="bold">ТОЛЬКО ПРЕДОПЛАТА! НИКАКОГО ТОРГА НЕТ!</span>
        </li>

        <li>
          После получения вашего платежа заказ будет оформлен. Как только товар
          будет готов к отправке, оператор(
          <a href="https://t.me/YAKUB0VlCH_OPERAT0RS">@YAKUB0VlCH_OPERATORS</a>)
          предоставит всю информацию в тот же чат, в котором вы сделали заказ.
          Не забывайте сохранять доступ к аккаунту, с которого был сделан заказ,
          мы не разглашаем информацию о заказе третьим лицам.
        </li>
      </ul>

      <img
        className="large"
        src={process.env.PUBLIC_URL + "/img/articles/1/1.jpg"}
        alt=""
      />

      <div className="textBlock">
        <p>
          Является офертой. Оплата выбранного товара по прайсу или запрос на
          оказание услуги, заявленной в переписке{" "}
          <a href="https://t.me/YAKUB0VlCH_OPERAT0RS">@YAKUB0VlCH_OPERATORS</a>{" "}
          , представляют собой акцепт оферты по оказанию услуг, поименованных в
          прайсе. Принятие Заказчиком оказанной услуги или покупка изделий
          фиксируется сообщением в чате{" "}
          <a href="https://t.me/YAKUB0VlCH_OPERAT0RS">@YAKUB0VlCH_OPERATORS</a>{" "}
          в произвольной форме. Исполнитель не несёт ответственности за
          дальнейшее использование Заказчиком полученного от Исполнителя
          продукта (изделия). Магазин продает товары без документов для личного
          пользования физических лиц в целях развития навыков обращения с
          оружием и самообороны. Мы верим, что вы не будете применять наши
          товары в корыстных целях.
        </p>
      </div>
    </>
  );
}

export default SecondArticle;
