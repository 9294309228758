import React from "react";
import { Helmet } from "react-helmet";

function CalibreArticle() {
  return (
    <>
      <Helmet>
        <title>
          Типы и калибры патронов для травматического оружия | Поле Чудес
        </title>
        <meta
          name="description"
          content="Узнайте о различных типах и калибрах патронов для травматического оружия. Поле Чудес - ваш надежный источник информации."
        />
        <meta
          name="keywords"
          content="патроны, калибры, травматическое оружие, ОООП, Поле Чудес"
        />
      </Helmet>

      <h1>Типы и калибры патронов для травматического оружия.</h1>
      <img
        src={process.env.PUBLIC_URL + "/img/articles/10/main.png"}
        alt=""
        className="large"
      />

      <p>
        Огнестрельное оружие ограниченного поражения (ОООП) предназначено для
        ношения и использования в целях самообороны. Для стрельбы из такого
        оружия чаще всего используются патроны травматического действия с
        резиновой пулей. На рынке представлено много патронов разного типа от
        разных производителей, которые отличаются энергетикой и массой метаемого
        снаряжения.
      </p>

      <p>
        Самые популярные патроны травматического действия для короткоствольного
        ОООП: (Дульная энергия – до 91 Дж)
      </p>

      <h3 className="mt-4">9РА</h3>
      <ul>
        <li>
          Высокая скорость полёта пули, повышенная проникающая способность.
        </li>
        <li>Масса пули от 0,6 до 1 г, диаметр – 10 мм</li>
        <li>Используется для стрельбы из МР-79-9ТМ (ПМ), МР-78-9ТМ (ПСМ),</li>
        <li>PM PRO 9 и других пистолетов (револьверов).</li>
      </ul>

      <h3 className="mt-4">45RUBBER</h3>
      <ul>
        <li>Патрон с повышенным останавливающим действием.</li>
        <li>Масса пули от 1,5 г до 1,73 г, диаметр – 13 мм.</li>
        <li>
          Используется для стрельбы из МР-80-13Т (ПМ), МР-353 (ПЯ) и других
          пистолетов.
        </li>
      </ul>

      <h3 className="mt-4">10X28</h3>
      <ul>
        <li>Баланс между проникающей и останавливающей способностью.</li>
        <li>Масса пули от 1 до 2,04 г, диаметр – от 10 до 12 мм.</li>
        <li>
          Используется для стрельбы из МР-356 (ПЯ), ПЛК-Т и других пистолетов.
        </li>
      </ul>

      <p>
        Представленные выше патроны являются наиболее популярными на
        отечественном рынке. В магазинах продаются и другие варианты, например:
        .45х30, 10х32, .44ТК, 10х22Т. Данные патроны создавались, как правило,
        под конкретную модель оружия и не стали универсальными.
      </p>

      <p>
        Патроны травматического действия одного калибра могут выпускаться
        разными производителями и с различными характеристиками. Рекомендуем
        тестировать и подбирать патрон под каждую конкретную модель оружия для
        проверки энергетики и надёжности работы автоматик
      </p>

      <p>
        Наиболее распространенными патронами для травматических пистолетов
        являются 9 Р.А., .45 Rubber и 10х28, каждый из которых имеет свои
        особенности и преимущества. Перед выбором оружия под конкретный патрон
        определитесь с целями и задачами, которые вы стремитесь решить.
      </p>
    </>
  );
}

export default CalibreArticle;
