import React from "react";
import { Link } from "react-router-dom";

function CatalogPart() {
  const products = [
    {
      text: "Травматические пистолеты",
      image: "/img/catalogPage/travmat.jpg",
      href: "traumatic-pistols",
    },
    {
      text: "Боевые пистолеты",
      image: "/img/catalogPage/colt1911.jpg",
      href: "сombat-pistols",
    },
    {
      text: "Боевые автоматы",
      image: "/img/catalogPage/m4.jpg",
      href: "сombat-rifles",
    },
    {
      text: "Револьверы",
      image: "/img/catalogPage/revolver.jpg",
      href: "revolvers",
    },
  ];

  return (
    <section className="catalog">
      <h2>Каталог</h2>
      <div className="row">
        {products.map((product, index) => {
          return (
            <Link className="block" to={`/catalog/${product.href}`}>
              <img src={process.env.PUBLIC_URL + product.image} alt="" />
              <div className="blurLayer"></div>
              <Link className="go" to={`/catalog/${product.href}`}>
                Перейти
              </Link>
            </Link>
          );
        })}
      </div>
    </section>
  );
}

export default CatalogPart;
