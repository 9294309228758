import React from "react";
import CatalogPart from "../../components/catalogPart/catalogPart.jsx";
import "./css/catalog.css";
import { Helmet } from "react-helmet";

function Catalog() {
  return (
    <>
      <Helmet>
        <title>Каталог</title>
        <meta
          name="description"
          content="Просмотрите наш каталог товаров. Поле Чудес предлагает широкий ассортимент продукции."
        />
        <meta
          name="keywords"
          content="каталог, товары, Поле Чудес, ассортимент"
        />
      </Helmet>
      <main className="catalogPage">
        <CatalogPart />
      </main>
    </>
  );
}

export default Catalog;
