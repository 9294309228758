import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";

function TravmatPistolsPart() {
  const products = [
    {
      title: "ОСА ПБ4-2",
      caliber: "18x45",
      price: "12.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "OCA.jpg",
    },
    {
      title: "МР79-9ТМ",
      caliber: "9РА",
      price: "15.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "mp79-9tm.jpg",
    },
    {
      title: "ТТ Лидер",
      caliber: "10х32Т",
      price: "15.200",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "tt-lider.jpg",
    },
    {
      title: "Фантом-Т",
      caliber: "9РА",
      price: "16.200",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "phantom.jpg",
    },
    {
      title: "Streamer 1014",
      caliber: "9РА",
      price: "16.400",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "streamer.jpg",
    },
    {
      title: "МР-78-9ТМ",
      caliber: "9РА",
      price: "16.900",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "mp-78-9tm.jpg",
    },
    {
      title: "ПМ20Т",
      caliber: "9РА",
      price: "20.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "pm20t.jpg",
    },
    {
      title: "ХОРХЕ-4",
      caliber: "9РА",
      price: "19.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "xopxe.png",
    },
    {
      title: "ПМ22Т",
      caliber: "9РА",
      price: "20.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "pm22t.jpg",
    },
    {
      title: "Wasp R",
      caliber: "9РА",
      price: "20.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "wasp-r.png",
    },
    {
      title: "Temp-1",
      caliber: "9РА",
      price: "21.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "temp-1.png",
    },
    {
      title: "XOPXE",
      caliber: "9РА",
      price: "21.500",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "xopxe-1.png",
    },
    {
      title: "TK717T",
      caliber: "9РА",
      price: "21.700",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "tk717T.png",
    },
    {
      title: "Стрела М45",
      caliber: "45RUBB",
      price: "22.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "arrow-m45.png",
    },
    {
      title: "ПМ17Т",
      caliber: "9РА",
      price: "23.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "pm17t.png",
    },
    {
      title: "АПС (Стечкин)",
      caliber: "9РА",
      price: "23.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "aps.png",
    },
    {
      title: "Grand Power T12",
      caliber: "10x28",
      price: "23.900",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "grandpowert12.png",
    },
    {
      title: "MP-353 (Ярыгин ПЯ)",
      caliber: "45RUBB",
      price: "27.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "mp-353.png",
    },
    {
      title: "Стрела М9Т",
      caliber: "9РА",
      price: "29.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "arrow-m9.png",
    },
    {
      title: "Гроза-01",
      caliber: "9РА",
      price: "36.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "thunder01.png",
    },
    {
      title: "ТТК-ДФ",
      caliber: "10х32Т",
      price: "41.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "ttk-df.png",
    },
    {
      title: "Гроза-041",
      caliber: "9РА",
      price: "41.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "thunder041.png",
    },
    {
      title: "Гроза-02",
      caliber: "9РА",
      price: "47.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "thunder02.png",
    },
    {
      title: "Тень-28",
      caliber: "10х28",
      price: "56.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "shadow28.png",
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const pageCount = Math.ceil(products.length / itemsPerPage);

  const showPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderPaginationLinks = () => {
    const links = [];
    for (let i = 1; i <= pageCount; i++) {
      links.push(
        <a
          href="#"
          key={i}
          className={`linkk ${i === currentPage ? "active" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            showPage(i);
          }}
        >
          {i}
        </a>
      );
    }
    return links;
  };

  const renderProducts = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return products.slice(startIndex, endIndex).map((product, index) => (
      <div className="block" key={index}>
        <img
          src={
            process.env.PUBLIC_URL + "/img/catalogPage/travmat/" + product.image
          }
          alt=""
        />
        <div className="textPart">
          <h3>
            {product.title} ({product.caliber})
          </h3>
          <h4>Комплектация</h4>
          <ul>
            {product.equipment.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <div className="row">
            <p className="price">{product.price} ₽</p>
            <a
              href={`https://t.me/YAKUB0VlCH_OPERAT0RS?text=Доброго%20времени%20суток.%20Интересует%20травматический%20пистолет%20модели%20${encodeURIComponent(
                product.title
              )}`}
              target="_blank"
              className="sendTo"
              rel="noopener noreferrer"
            >
              Заказать у оператора
            </a>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <Helmet>
        <title>Травматические пистолеты - Поле Чудес</title>
        <meta
          name="description"
          content="Купить травматические пистолеты в интернет-магазине 'Поле Чудес'. Широкий ассортимент травматического оружия."
        />
        <meta
          name="keywords"
          content="травматические пистолеты, травматическое оружие, купить пистолет"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="container">{renderProducts()}</div>
      <div className="pagination">{renderPaginationLinks()}</div>
    </>
  );
}

export default TravmatPistolsPart;
