import React from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
function Footer({ headerLinks }) {
  const location = useLocation();
  const currentLink = location.pathname;

  const handleActiveLink = (link) => {
    if (currentLink === link.href) {
      return "active";
    } else {
      return "";
    }
  };

  const importantInfo = [
    {
      text: "Основная информация о магазине",
      link: "/articles/2",
    },
    {
      text: "Как сделать заказ",
      link: "/articles/1",
    },
    {
      text: "Гарантии",
      link: "/articles/5",
    },
    {
      text: "Отказ от изделия",
      link: "/articles/7",
    },
    {
      text: "Насколько мощный травмат?",
      link: "/articles/6",
    },
    {
      text: "Как забрать клад?",
      link: "/articles/3",
    },
  ];
  return (
    <footer>
      <div className="container">
        <div className="block">
          <Link to="/" className="logo">
            П<span className="yellow">0</span>ле Чуд
            <span className="yellow">е</span>с
          </Link>
          <p>Вращайте барабан!</p>

          <div className="container-request">
            <a href="https://t.me/YAKUB0VlCH_OPERAT0RS?text=Доброго%20времени%20суток.%20Я%20с%20сайта%20Поле%20Чудес">
              Написать оператору
            </a>
          </div>
        </div>
        <div className="block">
          <h3>Сайт</h3>
          <ul>
            {headerLinks.map((link, index) => (
              <li>
                <Link to={link.href} className={handleActiveLink(link)}>
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="block">
          <h3>Важная информация</h3>
          <ul>
            {importantInfo.map((link, index) => (
              <li>
                <Link to={link.link}>{link.text}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
