import React from "react";
import { Helmet } from "react-helmet";
function FirstArticle() {
  return (
    <>
      <Helmet>
        <title>Приветствуем! Уважаемый покупатель! | Поле Чудес</title>
        <meta
          name="description"
          content="Добро пожаловать в наш онлайн магазин оружия! Мы предлагаем широкий ассортимент качественного оружия и аксессуаров. Поле Чудес - ваш надежный поставщик."
        />
        <meta
          name="keywords"
          content="онлайн магазин оружия, Поле Чудес, покупка оружия, безопасность, аксессуары"
        />
      </Helmet>
      <h1>Приветствуем! Уважаемый покупатель!</h1>
      <img
        src={process.env.PUBLIC_URL + "/img/articles/2/main.jpg"}
        alt=""
        className="large"
      />
      <p>
        Добро пожаловать в наш онлайн магазин оружия! Мы рады приветствовать вас
        и посодействовать в вашей покупке. Наша команда специалистов с большим
        опытом в области безопасности и продажи оружия гарантирует, что ваше
        покупательское Доверие - наш приоритет.
      </p>
      <img
        src={process.env.PUBLIC_URL + "/img/articles/2/2.jpg"}
        alt=""
        className="large"
      />
      <p>
        У нас вы найдете широкий ассортимент оружия высокого качества, от
        ведущих производителей, а также аксессуаров и сопутствующих товаров. Мы
        стремимся предоставлять только тщательно отобранные товары, которые
        соответствуют самым жестким стандартам безопасности.
      </p>
      <img
        src={process.env.PUBLIC_URL + "/img/articles/2/3.jpg"}
        alt=""
        className="large"
      />
      <p>
        Мы также гордимся нашим профессиональным обслуживанием клиентов. Наша
        команда готова ответить на любые ваши вопросы и помочь вам сделать
        правильный выбор. Ваша безопасность - наша забота.
      </p>
      <img
        src={process.env.PUBLIC_URL + "/img/articles/2/4.jpg"}
        alt=""
        className="large"
      />
      <p>
        Мы ценим доверие, которое вы оказываете, выбирая нас в качестве вашего
        поставщика оружия. Мы стремимся к долгосрочным отношениям с нашими
        клиентами и гарантируем вам высокое качество товаров и профессиональное
        обслуживание.
      </p>
      <img
        src={process.env.PUBLIC_URL + "/img/articles/2/5.jpg"}
        alt=""
        className="large"
      />
      <div className="textBlock">
        <p>
          Спасибо за то, что выбираете наш магазин. Мы с нетерпением ждем
          возможности служить вам и обеспечивать вас надежным оружием для защиты
          и спорта.
        </p>
      </div>
    </>
  );
}

export default FirstArticle;
