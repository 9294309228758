import React from "react";
import { Helmet } from "react-helmet";

function HowToKeepPatronsArticle() {
  return (
    <>
      <Helmet>
        <title>Как хранить оружие и патроны? | Поле Чудес</title>
        <meta
          name="description"
          content="Узнайте, как правильно хранить оружие и патроны в соответствии с законодательством. Поле Чудес - ваш надежный источник информации."
        />
        <meta
          name="keywords"
          content="хранение оружия, хранение патронов, безопасность, Поле Чудес, законодательство"
        />
      </Helmet>

      <h1>Как хранить оружие и патроны?</h1>
      <img
        src={process.env.PUBLIC_URL + "/img/articles/13/main.png"}
        alt=""
        className="large"
      />

      <p>
        Обязанность владельца правильно хранить оружие и патроны возникает с
        момента их приобретения. В этом руководстве описаны правила хранения
        оружия и патронов в соответствии с требованиями действующего
        законодательства.
      </p>

      <p>
        При хранении оружия и патронов по месту жительства руководствуйтесь
        следующими требованиями:{" "}
      </p>

      <h3 className="mb-1 mt-4">Где хранить?</h3>
      <p>
        В сейфах, сейфовых шкафах, металлических шкафах для хранения оружия,
        ящиках из высокопрочных материалов или деревянных ящиках, обитых
        железом, которые оборудованы как минимум одним замком
      </p>

      <h3 className="mb-1 mt-4">Как хранить?</h3>

      <ul>
        <li>С обеспечением сохранности</li>
        <li>В безопасных условиях</li>
        <li>Исключив доступ посторонних лиц</li>
      </ul>

      <p>
        Сейф для хранения оружия может быть куплен в магазине или изготовлен
        самостоятельно. Правила для владельцев оружия – физических лиц – не
        устанавливают минимальную толщину стенок сейфа, порядок крепления к
        стенам, конфигурацию запирающих устройств или наличие внутреннего ящика
        для раздельного хранения патронов и оружия.
      </p>

      <p>
        Допускается нахождение в одном отсеке сейфа оружия и патронов к нему,
        или хранение снаряжённых магазинов, отомкнутых от оружия. Патроны в
        сейфе могут находиться в упаковке производителя, специальной таре или
        россыпью. Главное – перед тем, как убрать оружие в сейф, убедитесь, что
        оно разряжено.
      </p>

      <p>
        Место временного пребывания — это не только гостиница, кемпинг,
        санаторий или туристская база. Временно пребывать с оружием можно на
        стрелковом объекте, в лесу и в других местах, где предполагается
        законное ношение или использование оружия. Например, в домике на
        территории охотничьих угодий.
      </p>

      <p>
        К хранению оружия по месту временного пребывания предъявляется одно
        требование – исключить доступ третьих лиц. Правила не содержат
        конкретного способа выполнения этого требования. Если нет возможности
        использовать сейф, жёсткий кофр или мягкий чехол со встроенными или
        внешними устройствами запирания, исключите возможность выстрела –
        используйте оружейный замок, при возможности извлеките затвор и примите
        другие меры, которые исключат несанкционированное использование оружия.
      </p>
    </>
  );
}

export default HowToKeepPatronsArticle;
