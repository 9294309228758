import React from "react";
import { Helmet } from "react-helmet";

function SeventhArticle() {
  return (
    <>
      <Helmet>
        <title>Отказ от изделия - Поле Чудес</title>
        <meta
          name="description"
          content="Информация о процессе отказа от изделия в интернет-магазине 'Поле Чудес'."
        />
        <meta
          name="keywords"
          content="отказ от изделия, возврат товара, интернет-магазин"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <h1>Отказ от изделия</h1>

      <img
        src={process.env.PUBLIC_URL + "/img/articles/7/main.jpg"}
        className="large"
        alt=""
      />

      <h3 className="mb-2">
        Сделал заказ, но товар не нравится по какой либо причине. Что делать?
        Какие действия?
      </h3>

      <p>
        Вы сделали заказ. Вам подготовили клад. Посмотрели, но товар не устроил.{" "}
        <br />
        Мы предоставляем возможность отказаться от заказа без объяснения причин
        и торгов!
      </p>

      <p>
        Последовательности действий нет! <br /> Осмотрели, не устроило, сообщили
        оператору, что товар вас не устраивает, кладете его на место туда где
        взяли, все.
      </p>

      <h3 className="mt-4">От вас потребуется 2 фото</h3>

      <ul>
        <li>Клад вне прикопа в полном комплекте</li>
        <li>
          Прикопанный клад на том же месте в соответствии фото от курьера
          который делал клад (Тот же ракурс)
        </li>
      </ul>

      <p>
        Курьер забирает клад и оператор перечисляет на удобный для вас счет
        деньги (С учетом комиссии)
      </p>

      <p>
        По вопросам приобретения к оператору:{" "}
        <a href="https://t.me/YAKUB0VlCH_OPERAT0RS">@YAKUB0VlCH_OPERATORS</a>
      </p>

      <div className="textBlock">
        <p>Работайте только с проверенными временем магазинами!</p>
      </div>
    </>
  );
}

export default SeventhArticle;
